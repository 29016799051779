import { useCallback } from 'react';
import { useUserWeb3, useTryConnect } from '@moverfinance/web3';
import { Wallet } from '@ethersproject/wallet';
import { JsonRpcProvider } from '@ethersproject/providers';
import { NFT } from 'constants/address';
import { L2_RPC } from 'constants/migrate';
import { injected } from 'connectors/injected';
import { Bridge } from 'utils/migrate';

const useSigner = () => {
  const { library } = useUserWeb3();

  return useCallback(async () => {
    if (!library) throw new Error('library is not found');
    return await library.getSigner();
  }, [library]);
};

export const useDeposit = () => {
  const getSigner = useSigner();
  const tryConnect = useTryConnect();

  return useCallback(
    async (
      tokenIds: Array<number | string>,
      waitL2: boolean,
      onStep: (steps: boolean[]) => void
    ) => {
      await tryConnect(injected);
      onStep([true, false, false]);
      const l1Signer = await getSigner();
      const l2Provider = new JsonRpcProvider(L2_RPC);
      const l2Signer = new Wallet(
        '0x9000000000000000000000000000000000000000000000000000000000000000',
        l2Provider
      );
      const bridge = await Bridge.init(l1Signer, l2Signer);
      const owner = await l1Signer.getAddress();
      const isApproved = await bridge.isApprovedForAll(NFT, owner);
      if (!isApproved) {
        const approveTx = await bridge.approveTokenForAll(NFT);
        await approveTx.wait();
      }
      onStep([true, true, false]);
      const depositTx = await bridge.deposit({
        l1TokenAddress: NFT,
        tokenIds,
        amount: tokenIds.length
      });

      if (!waitL2) return depositTx;
      await depositTx.wait();
      return await bridge.wait(depositTx.hash);
    },
    [getSigner, tryConnect]
  );
};
