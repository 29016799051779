import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUserWeb3 } from '@moverfinance/web3';
import styles from './card_overview.module.css';
import { Price } from 'components';
import { Attribute } from 'services';
export interface CardOverviewProps {
  imgSrc: string;
  ethPrice?: string;
  trait?: string; //todo 废弃
  owner?: string;
  race?: Attribute;
  lastPrice?: string;
  onsale?: boolean;
}

//TODO mock my account inventory
// if (nft.owner === account||owner=='0xA7dF655E1082978bad7a84De577C024dE2f11aa1') {
const CardOverview: React.FC<CardOverviewProps> = ({
  imgSrc,
  ethPrice,
  owner,
  race,
  lastPrice,
  onsale = false
}) => {
  const { t } = useTranslation();
  const { account } = useUserWeb3();
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        {onsale && <div className={styles.cardSale}>{t('common.onsale')}</div>}
        <img
          src={imgSrc ? imgSrc : ''}
          width={250}
          height={250}
          className={styles.cardImage}
          alt=""
        />
      </div>
      <div className={styles.summary}>
        <div className={styles.price}>
          {/* <p className={styles.purchased}>LAST PURCHASED AT</p>
          <div className={styles.priceContainer}>
            <div className={styles.cardPriceETH}>{`3.760 ${t(
              'common.eth'
            )}`}</div>
            <Price eth={'123312.123'} className={styles.cardPriceUSD} />
          </div>*/}

          {owner && owner === account && ethPrice && (
            <div>
              <p className={styles.purchased}>LAST PURCHASED AT</p>
            </div>
          )}
          {onsale && owner !== account ? (
            <p className={styles.priceTitle}>PRICE</p>
          ) : (
            <p></p>
          )}

          {owner !== account && ethPrice && onsale && (
            <div className={styles.priceContainer}>
              <div className={styles.cardPriceETH}>{`${ethPrice} ${t(
                'common.eth'
              )}`}</div>
              <Price eth={ethPrice} className={styles.cardPriceUSD} />
            </div>
          )}
          {owner && owner === account && lastPrice && (
            <div className={styles.priceContainer}>
              <div className={styles.cardPriceETH}>{`${lastPrice} ${t(
                'common.eth'
              )}`}</div>
              <Price eth={lastPrice} className={styles.cardPriceUSD} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardOverview;
