import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthenticated } from 'hooks';

interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isAuthenticated = useAuthenticated();

  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated ? (
          //@ts-ignore
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
