import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserWeb3, useTryConnect, isMobile } from '@moverfinance/web3';
import { UnsupportedChainIdError } from '@web3-react/core';
import styles from './wallet.module.css';
import { ExternalLink } from 'components';
import { track, trackName } from 'utils/mixpanel';
import { injected } from 'connectors/injected';
import { runCallback } from 'utils';
import { useLocation } from 'react-router-dom';

export interface WalletProps {
  hiddenMenu?: () => void;
  showNetSelect?: boolean;
}

const Wallet: React.FC<WalletProps> = ({
  hiddenMenu,
  showNetSelect = false
}) => {
  const { t } = useTranslation();
  const { active, error } = useUserWeb3();
  const [callback, setCallback] = useState(false);
  const tryConnect = useTryConnect();
  const location = useLocation();

  useEffect(() => {
    if (callback || !isMobile) return;
    if (error) {
      hiddenMenu && runCallback(hiddenMenu, false);
      setCallback(true);
    }
  }, [error, hiddenMenu, callback]);
  const isMenuChecked = (path: string) => {
    if(location.pathname.indexOf(path)>0){
      return true;
    }
    return false;
  }
  return (
    <>
      {window.ethereum ? (
        !active && isMobile ? (
          <div className={styles.walletConnect}>
            {showNetSelect && (
              <div className={styles.walletMenu}>
                <div className={styles.mobileMenu}>
                  <div className={`${styles.mobileMenuItem} ${isMenuChecked('assets') ? styles.mobileMenuItemActive : ''}`}>
                    <a href="/assets">
                      <div>
                        <div className={styles.mobileMenuItemDisabled}>Marketplace</div>
                      </div>
                    </a>
                  </div>
                  <a href={'/activity'}  className={`${styles.mobileMenuItem} ${isMenuChecked('activity') ? styles.mobileMenuItemActive : ''}`}>Activity</a>
                  <a href="https://openblox.io/games.html" className={styles.mobileMenuItem}>Games</a>
                  <a href="https://medium.com/@OpenBlox"  className={styles.mobileMenuItem}>Media</a>
                  <a href="https://docs.openblox.io/"  className={styles.mobileMenuItem}>Whitepaper</a>
                </div>
              </div>
            )
            }
            {showNetSelect && (
              <div className={styles.walletInfo}>
                <button
                  className={`${
                    error instanceof UnsupportedChainIdError
                      ? styles.walletDisabled
                      : styles.wallet
                  }`}
                  onClick={() => {
                    track(trackName.CONNECT_WALLET);
                    tryConnect(injected);
                  }}
                >
                  {t('common.connectWallet')}
                </button>
              </div>
            )}
            {!showNetSelect && (
              <button
                className={`${
                  error instanceof UnsupportedChainIdError
                    ? styles.walletDisabled
                    : styles.wallet
                }`}
                onClick={() => {
                  track(trackName.CONNECT_WALLET);
                  tryConnect(injected);
                }}
              >
                {t('common.connectWallet')}
              </button>
            )}
          </div>
        ) : !active?(
          <div className={styles.walletFlex}>
            <button
              className={`${
                error instanceof UnsupportedChainIdError
                  ? styles.walletDisabled
                  : styles.wallet
              }`}
              onClick={() => {
                track(trackName.CONNECT_WALLET);
                tryConnect(injected);
              }}
            >
              {t('common.connectWallet')}
            </button>
          </div>
        ):""
      ) : isMobile ? (
        <div className={styles.walletTop}>
          {showNetSelect && (
            <div className={styles.walletMenu}>
              <div className={styles.mobileMenu}>
                <div className={`${styles.mobileMenuItem} ${isMenuChecked('assets') ? styles.mobileMenuItemActive : ''}`}>
                  <a href="/assets">
                    <div>
                      <div className={styles.mobileMenuItemDisabled}>Marketplace</div>
                    </div>
                  </a>
                </div>
                <a href={'/activity'}  className={`${styles.mobileMenuItem} ${isMenuChecked('activity') ? styles.mobileMenuItemActive : ''}`}>Activity</a>
                <a href="https://openblox.io/games.html" className={styles.mobileMenuItem}>Games</a>
                <a href="https://medium.com/@OpenBlox"  className={styles.mobileMenuItem}>Media</a>
                <a href="https://docs.openblox.io/"  className={styles.mobileMenuItem}>Whitepaper</a>
              </div>
            </div>
          )
          }
          <div className={styles.walletInfo}>
            <ExternalLink
              className={styles.wallet}
              to={`https://metamask.app.link/dapp/${document.domain}`}
            >
              {t('common.connectWallet')}
            </ExternalLink>
          </div>
        </div>
      ) : (
        <ExternalLink
          to="https://metamask.io/download.html"
          className={styles.wallet}
        >
          {t('common.installMetaMask')}
        </ExternalLink>
      )}
    </>
  );
};
export default Wallet;
