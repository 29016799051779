export const MARKETPLACE = process.env.REACT_APP_MP_ADDRESS as string;
export const MP_VERSION = process.env.REACT_APP_MP_VERSION as string;
export const NFT = process.env.REACT_APP_NFT as string;
export const BATCH_ADDRESS = process.env.REACT_APP_BATCH_TRANSFER as string;
export const BRIDGE = {
  l1GatewayRouterLogic: process.env.REACT_APP_L1_GATEWAY_ROUTER_LOGIC,
  l2GatewayRouterLogic: process.env.REACT_APP_L2_GATEWAY_ROUTER_LOGIC,
  l1GatewayRouter: process.env.REACT_APP_L1_GATEWAY_ROUTER,
  l2GatewayRouter: process.env.REACT_APP_L2_GATEWAY_ROUTER,
  l1CustomGatewayLogic: process.env.REACT_APP_L1_CUSTOM_GATEWAY_LOGIC,
  l2CustomGatewayLogic: process.env.REACT_APP_L2_CUSTOM_GATEWAY_LOGIC,
  l1CustomGateway: process.env.REACT_APP_L1_CUSTOM_GATEWAY,
  l2CustomGateway: process.env.REACT_APP_L2_CUSTOM_GATEWAY,
  l1ProxyAdmin: process.env.REACT_APP_L1_PROXY_ADMIN,
  l2ProxyAdmin: process.env.REACT_APP_L2_PROXY_ADMIN
};
