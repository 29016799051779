import { useCallback, useMemo } from 'react';
import { ContractTransaction } from '@ethersproject/contracts';
import { useUserWeb3, useTryConnect } from '@moverfinance/web3';
import {
  MARKETPLACE as MARKETPLACE_ADDRESS,
  MP_VERSION
} from 'constants/address';
import { injected } from 'connectors/injected';
import { useIsApproved, useApprove } from './useApprove';
import { useMarkeplaceContract } from './useContract';
import { OrderInfo } from 'services';

export const useCreate = () => {
  const isApproved = useIsApproved();
  const tryConnect = useTryConnect();
  const approve = useApprove();
  const signTypedData = useSignTypedData();

  return useCallback(
    async (
      order: Omit<OrderInfo, 'nonce'>,
      onStep: (steps: boolean[]) => void
    ) => {
      const approved = await isApproved();

      await tryConnect(injected);
      onStep([true, false, false]);
      if (!approved) await approve();
      onStep([true, true, false]);
      return await signTypedData(order);
    },
    [isApproved, tryConnect, approve, signTypedData]
  );
};

export const useSignTypedData = () => {
  const { account, chainId, library } = useUserWeb3();
  const marketplace = useMarkeplaceContract();
  const domain = useMemo(
    () => ({
      name: 'marketplace',
      version: MP_VERSION,
      chainId,
      verifyingContract: MARKETPLACE_ADDRESS
    }),
    [chainId]
  );

  return useCallback(
    async (order: Omit<OrderInfo, 'nonce'>) => {
      if (!library || !account) throw new Error('web3 library not found');
      const nonce = (
        await marketplace().getNonce(order.nftAddress, order.tokenId)
      ).toNumber();
      const types = {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' }
        ],
        Order: [
          { name: 'nftAddress', type: 'address' },
          { name: 'tokenId', type: 'uint256' },
          { name: 'nonce', type: 'uint256' },
          { name: 'auctionType', type: 'uint8' },
          { name: 'tokenIds', type: 'uint256[]' },
          { name: 'seller', type: 'address' },
          { name: 'buyer', type: 'address' },
          { name: 'startingPrice', type: 'uint128' },
          { name: 'endingPrice', type: 'uint128' },
          { name: 'startedAt', type: 'uint128' },
          { name: 'endedAt', type: 'uint128' }
        ]
      };
      const signature = await new Promise<string>((resolve, reject) => {
        if (!library?.provider?.sendAsync)
          return reject(new Error('sendAsync not found'));
        library.provider.sendAsync(
          {
            method: 'eth_signTypedData_v4',
            params: [
              account,
              JSON.stringify({
                types,
                domain,
                primaryType: 'Order',
                message: {
                  ...order,
                  nonce
                }
              })
            ]
          },
          (error: any, res: { result: string }) => {
            if (error) return reject(error);
            resolve(res.result);
          }
        );
      });

      return {
        ...order,
        nonce,
        signature: {
          format: 'signTypedData_v4',
          sig: signature
        }
      };
    },
    [domain, library, account, marketplace]
  );
};

export const useCancel = () => {
  const marketplace = useMarkeplaceContract();

  return useCallback(
    (order: OrderInfo) =>
      marketplace().cancelOrder(order) as Promise<ContractTransaction>,
    [marketplace]
  );
};

export const useBid = () => {
  const marketplace = useMarkeplaceContract();

  return useCallback(
    async (order: OrderInfo, signature: string) => {
      const contract = marketplace();
      const price = await contract.getCurrentPrice(order);

      return contract.bid(order, signature, {
        value: price.toHexString()
      }) as Promise<ContractTransaction>;
    },
    [marketplace]
  );
};
