import { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserWeb3, isMobile } from '@moverfinance/web3';
import { ReactComponent as Enabled } from 'assets/icons/enabled.svg';
import { ReactComponent as ETH } from 'assets/icons/icon-eth.svg';
import { ReactComponent as Arbitrum } from 'assets/icons/icon-arbitrum.svg';
import styles from './account.module.css';
import { Balance, ExternalLink } from 'components';
import { copyText, shortenAddress } from 'utils';
import { track, trackName } from 'utils/mixpanel';
import { ReactComponent as OpenOutside } from 'assets/icons/open-outside.svg';
import { ETHERSCAN_URL, isL1NET, L1Name, L2Name } from '../../constants/chains';
import Tippy from '@tippyjs/react';
import { useBalance } from 'hooks';
import Select from 'react-select';
import { L1Balance } from '../../services';
const Account = () => {
  const { t } = useTranslation();
  const { account } = useUserWeb3();
  const [tippyText, setTippyText] = useState<string>(t('common.copy'));
  const [visible, setVisible] = useState(false);
  const [visibleM, setVisibleM] = useState(false);
  const [balanceMAINNET, setBalanceMAINNET] = useState(0);
  const balance = useBalance();
  const network = process.env.REACT_APP_ACTIVED_KEY as string;
  const location = useLocation();

  useEffect(() => {
    if (account) {
      let params = {
        address: account as string
      };
      L1Balance(params).then(data => {
        setBalanceMAINNET(data.balance);
      });
    }
  }, [account]);

  const isMenuChecked = (path: string) => {
    if(location.pathname.indexOf(path)>0){
      return true;
    }
    return false;
  }

  const copyAddress = () => {
    if (account && copyText(account)) {
      setTippyText(t('common.copied'));
    }
  };

  const showTippy = (ca?: string) => {
    setTippyText(t('common.copy'));
    if (ca === 'm') {
      setVisibleM(true);
    } else {
      setVisible(true);
    }
  };

  const hideTipppy = (ca?: string) => {
    if (ca === 'm') {
      setVisibleM(false);
    } else {
      setVisible(false);
    }
  };
  let Options = [
    {
      value: process.env.REACT_APP_L1_NAME,
      label: (
        <div className={styles.selectOption}>
          <ETH />
          {L1Name}
        </div>
      )
    },
    {
      value: process.env.REACT_APP_L2_NAME,
      label: (
        <div className={styles.selectOption}>
          <Arbitrum />
          {process.env.REACT_APP_L2_NAME === 'ARB_RINKEY' ? 'Arbitrum Test' : L2Name}
        </div>
      )
    }
  ];
  const OptionsL2 = [
    {
      value: process.env.REACT_APP_L2_NAME,
      label: (
        <div className={styles.selectOption}>
          <Arbitrum />
          {process.env.REACT_APP_L2_NAME === 'ARB_RINKEY' ? 'Arbitrum Test' : L2Name}
        </div>
      )
    }
  ];
  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      padding: 5,
      width: '150px',
      top: '25px'
    }),
    container: (provided: any) => ({
      ...provided,
      width:  isMobile ? '212px' : '120px',
      borderBottom: isMobile ? '1px solid #000000' : '0px'
    }),

    control: () => ({
      width: isMobile ? '212px' : '120px',
      marginTop: '0px'
    }),

    indicatorsContainer: () => ({
      display: 'none'
    }),

    valueContainer: (provided: any) => ({
      ...provided,
      display: 'flex',
      padding: '0px',
      background:
        "url('https://raw.githubusercontent.com/ourjs/static/gh-pages/2015/arrow.png') no-repeat scroll " +
        (isMobile ? '202px 14px' : '110px 30px') +
        ' transparent'
    }),

    singleValue: () => {
      const transition = 'opacity 300ms';
      return { transition };
    }
  };
  const selectOnChange = (e: any) => {
    const onChangeData = {
      value: e.value,
      name: e.label,
      result: Number(balanceMAINNET) > 0 ? Options.find(item => item.value === e.value) : OptionsL2.find(item => item.value === e.value)
    };
    console.log(onChangeData);
    if (isL1NET(e.value)) {
      window.location.href = process.env.REACT_APP_L1_HOST as string;
    } else if (!isL1NET(e.value)) {
      window.location.href = process.env.REACT_APP_L2_HOST as string;
    }
    return onChangeData;
  };
  return (
    <div className={styles.account}>
      <div className={styles.pc}>
        <div className={styles.pcETH}>
          {Number(balanceMAINNET) > 0 &&
          <Select
            styles={customStyles}
            isSearchable={false}
            defaultValue={Options[network === process.env.REACT_APP_L1_NAME ? 0 : Options.length-1] }
            onChange={e => selectOnChange(e)}
            options={Options}
          />
          }
          {Number(balanceMAINNET) <= 0 &&
          <Select
            styles={customStyles}
            isSearchable={false}
            defaultValue={OptionsL2[network === process.env.REACT_APP_L1_NAME ? 0 : OptionsL2.length-1] }
            onChange={e => selectOnChange(e)}
            options={OptionsL2}
          />
          }
        </div>
        <div className={styles.accountInfo}>
          <div className={styles.accountBalance}>
            <Balance className={styles.balance} balance={balance} />
            <Enabled />
          </div>
          {account && (
            <div className={styles.accountInfoAddress}>
              <ExternalLink
                to={`${ETHERSCAN_URL}/address/${account}`}
                className={styles.accountAddress}
              >
                <p>{shortenAddress(account)}&nbsp;</p>
              </ExternalLink>
            </div>
          )}
          {account && (
            <Tippy content={tippyText} visible={visible}>
              <div
                className={styles.address}
                onClick={copyAddress}
                onMouseEnter={() => showTippy()}
                onMouseLeave={() => hideTipppy()}
              >
                <OpenOutside />
              </div>
            </Tippy>
          )}
        </div>
        <div className={styles.accountLink}>
          <NavLink
            onClick={() => track(trackName.CLICK_MY_ACCOUNT, { 'Address': account })}
            to="/account"
            className={styles.accountText}
            activeClassName={styles.accountActive}
          >
            {t('layouts.header.account')}
          </NavLink>
        </div>
      </div>

      <div className={styles.mobile}>
        <div className={styles.mobileMenu}>
          <div className={`${styles.mobileMenuItem} ${isMenuChecked('assets') ? styles.mobileMenuItemActive : ''}`}>
            <a href="/assets">
              <div>
                <div className={styles.mobileMenuItemDisabled}>Marketplace</div>
              </div>
            </a>
          </div>
          <NavLink className={`${styles.mobileMenuItem} ${isMenuChecked('activity') ? styles.mobileMenuItemActive : ''}`}  to={'/activity'} >Activity</NavLink>
          <a className={styles.mobileMenuItem} href="https://openblox.io/games.html" target={"_blank"} rel="noreferrer">Games</a>
          <a className={styles.mobileMenuItem} href="https://medium.com/@OpenBlox"  target={"_blank"} rel="noreferrer">Media</a>
          <a className={styles.mobileMenuItem} target={"_blank"} href="https://docs.openblox.io/" rel="noreferrer">Whitepaper</a>

          {Number(balanceMAINNET) > 0 &&
          <Select
            styles={customStyles}
            isSearchable={false}
            defaultValue={Options[network === process.env.REACT_APP_L1_NAME ? 0 : Options.length-1] }
            onChange={e => selectOnChange(e)}
            options={Options}
          />
          }
          {Number(balanceMAINNET) <= 0 &&
          <Select
            styles={customStyles}
            isSearchable={false}
            defaultValue={OptionsL2[network === process.env.REACT_APP_L1_NAME ? 0 : OptionsL2.length-1] }
            onChange={e => selectOnChange(e)}
            options={OptionsL2}
          />
          }
        </div>
        <div className={styles.accountInfo}>
          <div className={styles.accountBalance}>
            <Balance className={styles.balance} balance={balance} />
            <Enabled />
          </div>
          {account && (
            <div className={styles.accountView}>
              <ExternalLink
                to={`${ETHERSCAN_URL}/address/${account}`}
                className={styles.address}
              >
                <p>{shortenAddress(account)}&nbsp;</p>
              </ExternalLink>
              <Tippy content={tippyText} visible={visibleM}>
                <div
                  className={styles.address}
                  onClick={copyAddress}
                  onMouseEnter={() => showTippy('m')}
                  onMouseLeave={() => hideTipppy('m')}
                >
                  <OpenOutside />
                </div>
              </Tippy>
            </div>
          )}
        </div>
        <div className={styles.accountLink}>
          <a href="/account" className={styles.accountText} onClick={()=>track(trackName.CLICK_MY_ACCOUNT, { 'Address': account })}>
            {t('layouts.header.account')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Account;
