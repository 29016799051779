import { useState, useEffect } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { useBlockNumber, useUserWeb3 } from '@moverfinance/web3';

export const useBalance = (owner?: string) => {
  const { library, account } = useUserWeb3();
  const blockNumber = useBlockNumber();
  const [balance, setBalance] = useState(BigNumber.from('0'));
  useEffect(() => {
    const ownerAddress = owner || account;
    if (!ownerAddress || !library) return;
    library.getBalance(ownerAddress).then((balance: BigNumber) => {
      setBalance(balance);
    });
  }, [library, blockNumber, account, owner]);

  return balance;
};
