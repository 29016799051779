import { Route, Switch, Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAuthenticated } from 'hooks';
import PrivateRoute from './PrivateRoute';

import Account from 'views/Account';
import Asset from 'views/Asset';
import Activity from 'views/Activity';
// import Home from 'views/Home';
import Login from 'views/Login';
import Marketplace from 'views/Marketplace';
import Sell from 'views/Sell';
import NotFound from 'views/NotFound';
import { isL1NET } from '../constants/chains';

const Routes = () => {
  const isAuthenticated = useAuthenticated();
  const location = useLocation<{ from: string }>();
  const { from } = location.state || { from: '/' };
  return (
    <Switch>
      {/* <Route exact path="/" component={Home} /> */}
      <Redirect exact from="/" to="/assets" />
      <Route path="/login">
        {isAuthenticated ? <Redirect to={from} /> : <Login />}
      </Route>

      {isL1NET() && <PrivateRoute exact path="/assets" component={Account}/>}
      {isL1NET() && <PrivateRoute exact path="/assets/:assetId" component={Account}/>}
      {isL1NET() && <PrivateRoute exact path="/assets/:assetId/sell" component={Account}/>}
      {isL1NET() && <PrivateRoute path="/account" component={Account} />}
      {isL1NET() && <PrivateRoute exact path="/assets" component={Account}/>}
      {isL1NET() && <PrivateRoute exact path="/activity" component={Account}/>}

      {!isL1NET() && <Route exact path="/assets" component={Marketplace}/>}
      {!isL1NET() && <Route exact path="/assets/:assetId" component={Asset}/>}
      {!isL1NET() && <Route exact path="/assets/:assetId/sell" component={Sell}/>}
      {!isL1NET() && <PrivateRoute path="/account" component={Account} />}
      {!isL1NET() && <Route exact path="/assets" component={Marketplace}/>}
      {!isL1NET() && <Route exact path="/activity" component={Activity}/>}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;
