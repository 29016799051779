import { isMainnet } from 'utils/network';
import { strEvent, strNewWorkFullName } from '../utils';

const ETH = 1;
const RINKEBY = 4;
const ARBITRUM = 42161;
const ARB_RINKEY = 421611;

export const CHAIN_IDS: Record<string, number> = {
  ETH,
  RINKEBY,
  ARBITRUM,
  ARB_RINKEY
} as const;

export const INFO = {
  [CHAIN_IDS.ETH]: {
    chainID: CHAIN_IDS.ETH,
    name: 'Ethereum',
    explorerUrl: 'https://etherscan.io',
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  },
  [CHAIN_IDS.ARBITRUM]: {
    chainID: CHAIN_IDS.ARBITRUM,
    name: 'Arbitrum',
    explorerUrl: 'https://arbiscan.io',
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  },
  [CHAIN_IDS.RINKEBY]: {
    chainID: CHAIN_IDS.RINKEBY,
    name: 'Rinkeby',
    explorerUrl: 'https://rinkeby.etherscan.io',
    rpcUrls: ['https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  },
  [CHAIN_IDS.ARB_RINKEY]: {
    chainID: CHAIN_IDS.ARB_RINKEY,
    name: 'Arbitrum Rinkeby',
    explorerUrl: 'https://testnet.arbiscan.io',
    rpcUrls: ['https://rinkeby.arbitrum.io/rpc'],
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  }
};

export const SUPPORTED_IDS = isMainnet
  ? [ETH, ARBITRUM]
  : [RINKEBY, ARB_RINKEY];
export const ACTIVED_ID =
  CHAIN_IDS[process.env.REACT_APP_ACTIVED_KEY as string] || ETH;

export const ETHERSCAN_URL = INFO[ACTIVED_ID]?.explorerUrl ?? '';

const L1NET = ['ETH', 'RINKEBY'];

export const envNetworkKey = strEvent(
  strNewWorkFullName(process.env.REACT_APP_ACTIVED_KEY as string).toLowerCase()
);

export const isL1NET = (key?: string) => {
  return L1NET.filter(e => e === (key || process.env.REACT_APP_ACTIVED_KEY as string)).length > 0;
};

export const L1Name = strEvent(
  strNewWorkFullName(process.env.REACT_APP_L1_NAME as string).toLowerCase()
);
export const L2Name = strEvent(
  strNewWorkFullName(process.env.REACT_APP_L2_NAME as string).toLowerCase()
);
