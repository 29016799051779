import React, { InputHTMLAttributes, ChangeEvent } from 'react';
import styles from './input.module.css';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputChange: (value: string) => void;
  className?: string;
}
const disableKeyCode = [37, 38, 39, 40];
const Input: React.FC<InputProps> = ({
  name = '',
  type = 'text',
  value,
  placeholder = '',
  inputMode = 'text',
  inputChange,
  className = ''
}) => {
  const onInputChanged = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target.value;
    inputChange(value);
  };

  const clickHandler = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    target.value = '';
    target.focus();
    target.value = value;
  };

  return (
    <input
      className={`${styles.input} ${className}`}
      name={name}
      type={type}
      inputMode={inputMode}
      value={value}
      onChange={onInputChanged}
      onClick={clickHandler}
      onKeyDown={event => {
        if (
          inputMode === 'numeric' &&
          (event.key === 'e' ||
            event.key === 'E' ||
            event.key === '+' ||
            event.key === '-')
        ) {
          event.preventDefault();
        }
        if (
          inputMode === 'decimal' &&
          disableKeyCode.filter(e => e === event.keyCode).length
        ) {
          event.preventDefault();
        }
      }}
      onWheel={e => {
        if (inputMode === 'numeric') e.currentTarget.blur();
      }}
      placeholder={placeholder}
      step={0.1}
    />
  );
};

export default Input;
