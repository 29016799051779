import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Provider as Web3Provider } from '@moverfinance/web3';
import App from './App';
import { injected } from './connectors/injected';
import { history, store, persist } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import './i18n';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Web3Provider
      autoConnect
      injectedConnector={injected}
      error={<div>Network Error</div>}
    >
      <Provider store={store}>
        {
          //@ts-ignore
          <PersistGate loading={null} persistor={persist}>
            <App history={history} />
          </PersistGate>
        }
      </Provider>
    </Web3Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
