import { useCallback } from 'react';
import { useContract } from '@moverfinance/web3';
import {
  MARKETPLACE as MARKETPLACE_ADDRESS,
  NFT as NFT_ADDRESS,
  BATCH_ADDRESS,
} from 'constants/address';
import MARKPALCE_ABI from 'abis/marketplace.json';
import NFT_ABI from 'abis/nft.json';
import BATCH_TRANSFER_ABI from 'abis/batchTransfer.json';
import { Nft, Marketplace, BatchTransfer } from 'abis/types';

export const useMarkeplaceContract = () => {
  const contract = useContract<Marketplace>(MARKPALCE_ABI, true);

  return useCallback(() => contract(MARKETPLACE_ADDRESS), [contract]);
};

export const useRealmCoreContract = () => {
  const contract = useContract<Nft>(NFT_ABI, true);

  return useCallback(() => contract(NFT_ADDRESS), [contract]);
};

export const useBatchTransferContract = () => {
  const contract = useContract<BatchTransfer>(BATCH_TRANSFER_ABI, true);
  return useCallback(() => contract(BATCH_ADDRESS), [contract]);
};
