import { useTranslation } from 'react-i18next';
import { formatEther } from '@ethersproject/units';
import { trimBalance } from 'utils';
import { BigNumber } from '@ethersproject/bignumber';
export interface BalanceProps {
  className?: string;
  balance: BigNumber;
}

const Balance = ({ className = '', balance = BigNumber.from('0') }) => {
  const { t } = useTranslation();

  return (
    <p className={className}>
      {balance && `${trimBalance(formatEther(balance))} ${t('common.eth')}`}
    </p>
  );
};

export default Balance;
