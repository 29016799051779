import React from 'react';
import styles from './button.module.css';

export type ButtonType = 'submit' | 'button' | 'reset';
export type ButtonMode = 'primary' | 'normal' | 'danger';

export interface ButtonProps {
  children: React.ReactNode;
  mode?: ButtonMode;
  type?: ButtonType;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  style?: {};
}

const Button: React.FC<ButtonProps> = ({
  children,
  mode = 'primary',
  type = 'button',
  disabled = false,
  className = '',
  onClick,
  style = {}
}) => {
  return (
    <button
      style={style}
      type={type}
      disabled={disabled}
      className={`${styles.button} ${styles[mode]} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
