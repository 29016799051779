import axios from 'axios';
import message from 'components/message';
import { getAppCheckToken, appCheck } from './firebase';
const INTERNAL_SERVER_ERROR = 500;

const instance = axios.create();

instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(
  async (config) => {
    const token = await getAppCheckToken(appCheck);
    config.headers['X-Firebase-AppCheck'] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

instance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    let desc = '';
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status < INTERNAL_SERVER_ERROR) {
        if (error.response.data?.messages) {
          desc = error.response.data.messages.join(' ');
          message.warning(desc, 5);
        } else {
          // clientError
        }
      } else {
        // serverError
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // connectionError
    } else {
      // Something happened in setting up the request that triggered an Error
      // message = 'An error occurred while setting up an API request.';
      // requestError
    }
    return Promise.reject(desc);
  }
);

export default instance;
