import React from 'react';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import Routes from './routes';
import 'styles/index.css';
interface AppProps {
  history: History;
}
const mixpanel = require('mixpanel-browser');
mixpanel.init('e3158fd3a93cf90dd08896662d0936d3', {debug: true,ignore_dot:true});
const App: React.FC<AppProps> = ({ history }) => {
  return (
    //@ts-ignore
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  );
};

export default App;
