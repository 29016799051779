import { useCallback, useEffect, useState } from 'react';
import { NavLink, RouteComponentProps, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserWeb3 } from '@moverfinance/web3';
import styles from './asset.module.css';
import { BaseLayout } from 'layouts';
import { formatEther } from '@ethersproject/units';
import { useAuthenticated, useBalance, useBid, useCancel } from 'hooks';
import { Button, CardOverview, Modal, Price, Table, Wallet } from 'components';
import CardRace from '../../../components/Card/CardRace';
import { shortenAddress, strDateTime, strEnDateTime, strEvent } from 'utils';
import { NFT as NFT_ADDRESS } from 'constants/address';
import { isL1NET } from '../../../constants/chains';

import message from 'components/message';
import {
  Activity,
  Attribute,
  listActivitiesByNFT,
  listAttributes,
  listNFTs,
  listOrders,
  NFT,
  Order
} from 'services';
import { ReactComponent as LinkOutside } from 'assets/icons/link-outside.svg';
// body icons
import { ReactComponent as Head } from 'assets/icons/head.svg';
import { ReactComponent as Arms } from 'assets/icons/arms.svg';
import { ReactComponent as Body } from 'assets/icons/body.svg';
import { ReactComponent as Horns } from 'assets/icons/horns.svg';
import { ReactComponent as Legs } from 'assets/icons/legs.svg';
import { ReactComponent as Tail } from 'assets/icons/tail.svg';
import { track, trackName } from 'utils/mixpanel';

interface ParamTypes {
  assetId: string;
}

interface AssetProps extends RouteComponentProps {}

type Status = 'buy' | 'sell' | 'cancel' | 'breed' | '';

const Asset: React.FC<AssetProps> = ({ history, location }) => {
  const { t } = useTranslation();
  const bid = useBid();
  const cancel = useCancel();
  const balance = useBalance();
  const isAuthenticated = useAuthenticated();
  const { assetId } = useParams<ParamTypes>();
  const { account } = useUserWeb3();
  const [status, setStatus] = useState<Status>('');
  const [buying, setBuying] = useState(false);
  const [back, setBack] = useState(false);
  const [showBuy, setShowBuy] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [nft, setNft] = useState<NFT>({} as NFT);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [attribute, setAttribute] = useState<Attribute>();
  const [order, setOrder] = useState<Order>({} as Order);
  const [activities, setActivities] = useState<Activity[]>([]);

  const canBuy = useCallback(() => {
    return Number(nft?.order?.price) <= Number(formatEther(balance));
  }, [nft, balance]);

  const checkoutText = useCallback(() => {
    if (canBuy()) {
      if (buying) {
        return 'BUYING';
      } else {
        return 'CHECKOUT';
      }
    } else {
      return 'NOT ENOUGH FUND';
    }
  }, [buying, canBuy]);
  const buyAsset = useCallback(() => {
    if (order) {
      const bidOrder = order.order;
      const signature = order.signature;
      if (signature) {
        bid(bidOrder, signature.sig)
          .then(tx => {
            setBuying(true);
            return tx.wait();
          })
          .then(receipt => {
            if (receipt.status) {
              setShowBuy(false);
              setBuying(false);
              message.success('Buy success! Please update your page!');
              setBack(true);
            }
          })
          .catch(error => {
            message.warning(error.error?.message || error.message, 5);
          });
      }
    }
  }, [order, bid]);

  useEffect(() => {
    if (back) {
      history.push({
        pathname: '/account'
      });
    }
  }, [back]); // eslint-disable-line react-hooks/exhaustive-deps
  const cancelAsset = useCallback(() => {
    if (order) {
      const { order: cancelOrder } = order;
      cancel(cancelOrder)
        .then(() => {
          setShowCancel(false);
          message.success('Cancel success! Please update your page!');
        })
        .catch(error => {
          message.warning(error.error?.message || error.message, 5);
        });
    }
  }, [order, cancel]);

  const backView = () => {
    // if (account && nft.owner === account) {
    //   history.push({
    //     pathname: '/account'
    //   });
    // } else {
    //   history.push({
    //     pathname: '/assets'
    //   });
    // }
    history.goBack();
  };

  useEffect(() => {
    const params = {
      nftAddress: NFT_ADDRESS,
      tokenId: assetId
    };
    listNFTs(params).then(data => {
      setNft(data.nfts[0]);
    });
    listAttributes(params).then(data => {
      setAttributes(data.attributes);
      setAttribute(data.attributes?.filter(a => a.key === 'race')[0]);
    });
    listActivitiesByNFT(params).then(data => {
      setActivities(data.activities);
    });
  }, [assetId]);

  useEffect(() => {
    if (nft?.order?.orderId) {
      listOrders({
        orderId: nft.order.orderId,
        sig: true
      }).then(data => {
        setOrder(data.orders[data.orders.length - 1]);
      });
    }
  }, [nft]);

  useEffect(() => {
    if (account) {
      setShowBuy(false);
      //TODO mock my account inventory
      // if (nft.owner === account||nft?.owner=='0xA7dF655E1082978bad7a84De577C024dE2f11aa1') {
      if (nft?.owner === account) {
        if (nft?.order?.onsale) {
          setStatus('cancel');
        } else {
          setStatus('sell');
        }
      } else if (nft?.order?.onsale) {
        setStatus('buy');
      }
    } else if (nft?.order?.onsale) {
      setStatus('buy');
    } else {
      setStatus('');
    }
    if (isL1NET()) {
      setStatus('');
    }
  }, [account, nft]);

  const Trade = () => {
    switch (status) {
      case 'buy': {
        return (
          <Button className={'buy'} onClick={() => {
            track(trackName.CLICK_BUY_NOW, { TokenID: nft?.tokenId });
            setShowBuy(true);
          }}>
            {t('views.asset.buyNow')}
          </Button>
        );
      }
      case 'sell': {
        return (
          <NavLink to={`/assets/${assetId}/sell`} className={'trade'} onClick={() => {
            track(trackName.CLICK_SELL, { Address: account });
          }}>
            {t('views.asset.sell')}
          </NavLink>
        );
      }
      case 'cancel': {
        return (
          <Button
            className={`${styles.cancelListing} ${styles.cancelListingLarge}`}
            onClick={() => setShowCancel(true)}
          >
            {t('views.asset.cancelListing')}
          </Button>
        );
      }
      default: {
        return null;
      }
    }
  };

  const columns = [
    {
      title: '',
      key: 'marginL',
      width: '10px',
      render: (value: string) => {
        if (value) {
          return <span></span>;
        }
      }
    },
    {
      title: 'EVENT',
      key: 'event',
      width: '41px',
      render: (value: string) => {
        if (value) {
          return <span className={styles.event}>{value}</span>;
        }
      }
    },
    {
      title: 'UNIT PRICE',
      key: 'price',
      width: '90px',
      render: (value: string) => {
        if (value) {
          return <span>{value} eth</span>;
        }
      }
    },
    {
      title: 'FROM',
      key: 'from',
      width: '91px',
      render: (value: string) => {
        if (value) {
          return (
            <NavLink
              to={{
                pathname: '/account',
                state: { owner: value, nftAddress: nft?.nftAddress }
              }}
              className={styles.externalLink}
            >
              {shortenAddress(value)}
            </NavLink>
          );
        } else {
          return null;
          //TODO MOCK DATA
          // return(
          // <ExternalLink to={`${ETHERSCAN_URL}address/fabcdefghigklmnopqrstuvwxyz`} className={styles.externalLink}>
          //   {shortenAddress("fabcdefghigklmnopqrstuvwxyz")}
          // </ExternalLink>)
        }
      }
    },
    {
      title: 'TO',
      key: 'to',
      width: '91px',
      render: (value: string) => {
        if (value) {
          return (
            <NavLink
              to={{
                pathname: '/account',
                state: { owner: value, nftAddress: nft?.nftAddress }
              }}
              className={styles.externalLink}
            >
              {shortenAddress(value)}
            </NavLink>
          );
        } else {
          return null;
          //TODO MOCK DATA
          // return (<ExternalLink to={`${ETHERSCAN_URL}address/fabcdefghigklmnopqrstuvwxyz`} className={styles.externalLink}>
          //   {shortenAddress("fabcdefghigklmnopqrstuvwxyz")}
          // </ExternalLink>)
        }
      }
    },
    {
      title: 'DATE',
      key: 'date',
      width: '65px'
    },
    {
      title: '',
      key: 'marginR',
      width: '10px',
      render: (value: string) => {
        if (value) {
          return <span></span>;
        }
      }
    }
  ];

  const dataSource = activities.map(activity => {
    return {
      marginL: '',
      event: strEvent(activity.event),
      price: activity.price || '',
      from: activity.from || '',
      to: activity.to || '',
      date: strDateTime(new Date(activity.ts)),
      marginR: ''
    };
  });

  return (
    <BaseLayout>
      <div className={styles.asset}>
        <div className={styles.inner}>
          <div className={styles.nav}>
            <button
              className={`${styles.button} ${styles.prevBtn}`}
              onClick={backView}
            >
              Back
            </button>
          </div>
          <div className={styles.content}>
            <div className={styles.infoHeaderMobile}>
              <div className={styles.infoDesc}>
                <p className={styles.infoName}>Blox &nbsp;#{nft?.tokenId}</p>
              </div>
            </div>
            <div className={styles.card}>
              <CardOverview
                imgSrc={nft?.imageUrl}
                ethPrice={nft?.order?.price}
                lastPrice={nft?.order?.lastPrice}
                owner={nft?.owner}
                race={attributes.filter(a => a.key === 'race')[0]}
                onsale={nft?.order?.onsale}
              />
              <div className={styles.tradeView}>
                <Trade />
                {/*<Button className={styles.cancelListing} onClick={() => setShowCancel(true)}>
                  {t('views.asset.breed')}
                </Button>*/}
              </div>
            </div>
            <div className={styles.rightView}>
              <div className={styles.info}>
                <div className={styles.infoHeader}>
                  <div className={styles.infoDesc}>
                    <p className={styles.infoName}>
                      Blox &nbsp;#{nft?.tokenId}
                    </p>
                  </div>
                </div>
                <p className={styles.infoCaption}>About</p>
                <div className={styles.infoCard}>
                  <div className={styles.infoCardTop}>
                    <div className={styles.infoCardTopRow}>
                      <p className={styles.infoSubject}>CLASS</p>
                      <p className={styles.infoText}>
                        <CardRace race={nft?.properties?.race}></CardRace>{' '}
                        {nft?.properties?.race}
                      </p>
                    </div>
                    <div className={styles.infoCardTopRow}>
                      <p className={styles.infoSubject}>BORN AT</p>
                      <p className={styles.infoText}>
                        {nft?.properties?.bornAt &&
                          strEnDateTime(new Date(nft?.properties?.bornAt))}
                      </p>
                    </div>
                    <div className={styles.infoCardTopRow}>
                      <p className={styles.infoSubject}>TRAIT</p>
                      <p className={styles.infoText}>
                        {attribute && (
                          <div className={styles.trait}>
                            <p className={styles.traitInfo}>
                              {attribute?.value}
                              {' - '}
                              {(
                                (attribute?.count / attribute?.total) *
                                100
                              ).toFixed(0)}
                              %
                            </p>
                          </div>
                        )}
                      </p>
                    </div>
                  </div>
                  <p className={styles.infoSubject}>OWNER</p>
                  <div className={styles.infoOwner}>
                    <NavLink
                      onClick={() => track(trackName.CLICK_OWNER_ADDRESS, {
                        Address: nft?.owner
                      })}
                      to={{
                        pathname: '/account',
                        state: {
                          owner: nft?.owner,
                          nftAddress: nft?.nftAddress
                        }
                      }}
                      className={styles.infoOwnerAddress}
                    >
                      {nft?.owner}
                    </NavLink>
                    <NavLink
                      to={{
                        pathname: '/account',
                        state: {
                          owner: nft?.owner,
                          nftAddress: nft?.nftAddress
                        }
                      }}
                      style={{ display: 'flex' }}
                    >
                      <LinkOutside />
                    </NavLink>
                  </div>
                </div>
                {/*<p className={styles.infoCaption}>Stats</p>
                <div className={styles.infoCardStates}>
                  <div className={styles.infoCardStatesText}>
                    <div className={styles.infoSubject}>HP</div>
                    <div className={styles.infoCardStatesTextValue}>
                      <Hp />
                      &nbsp;&nbsp;35
                    </div>
                  </div>
                  <div className={styles.infoCardStatesText}>
                    <div className={styles.infoSubject}>SPEED</div>
                    <div className={styles.infoCardStatesTextValue}>
                      <Speed />
                      &nbsp;&nbsp;45
                    </div>
                  </div>
                  <div className={styles.infoCardStatesText}>
                    <p className={styles.infoSubject}>LUCK</p>
                    <div className={styles.infoCardStatesTextValue}>
                      <Luck />
                      &nbsp;&nbsp;31
                    </div>
                  </div>
                </div>*/}
                <p className={styles.infoCaption}>Body Parts</p>
                <div className={styles.infoCardBodyParts}>
                  <div className={styles.infoCardBodyPartsText}>
                    <div className={styles.infoSubject}>BODY</div>
                    <div className={styles.infoCardBodyPartsTextValue}>
                      <Body />
                      <span>{nft?.properties?.body}</span>
                    </div>
                  </div>
                  <div className={styles.infoCardBodyPartsText}>
                    <div className={styles.infoSubject}>HEAD</div>
                    <div className={styles.infoCardBodyPartsTextValue}>
                      <Head />
                      <span>{nft?.properties?.head}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.infoCardBodyParts}>
                  <div className={styles.infoCardBodyPartsText}>
                    <div className={styles.infoSubject}>ARMS</div>
                    <div className={styles.infoCardBodyPartsTextValue}>
                      <Arms />
                      <span>{nft?.properties?.arm}</span>
                    </div>
                  </div>
                  <div className={styles.infoCardBodyPartsText}>
                    <div className={styles.infoSubject}>LEGS</div>
                    <div className={styles.infoCardBodyPartsTextValue}>
                      <Legs />
                      <span>{nft?.properties?.leg}</span>
                    </div>
                  </div>
                </div>
                <div className={styles.infoCardBodyParts}>
                  <div className={styles.infoCardBodyPartsText}>
                    <div className={styles.infoSubject}>TAIL</div>
                    <div className={styles.infoCardBodyPartsTextValue}>
                      <Tail />
                      <span>{nft?.properties?.back}</span>
                    </div>
                  </div>
                  <div className={styles.infoCardBodyPartsText}>
                    <div className={styles.infoSubject}>HORNS</div>
                    <div className={styles.infoCardBodyPartsTextValue}>
                      <Horns />
                      <span>{nft?.properties?.horn}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.trading}>
                <div className={styles.infoHeader}>
                  <div className={styles.infoDesc}>
                    <p className={styles.infoName}>&nbsp;</p>
                  </div>
                </div>
                <div className={styles.history}>
                  <h1 className={styles.infoCaption}>{'Trading History'}</h1>
                  <div className={styles.activity}>
                    <Table columns={columns} dataSource={dataSource} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal visible={showBuy} title={'Complete checkout'} onClose={setShowBuy}>
        {isAuthenticated ? (
          <div className={styles.buy}>
            <div className={styles.buyContent}>
              <div className={styles.buyItem}>
                <div className={styles.buyName}>
                  <div className={styles.buyCard}>
                    <img
                      alt="asset"
                      width={48}
                      height={48}
                      src={nft?.imageUrl}
                    />
                  </div>
                  <div className={styles.buyCardInfo}>
                    <div className={styles.buyCardInfoLeft}>
                      <span className={styles.buyRace}>
                          <CardRace race={nft?.properties?.race}></CardRace>{' '}
                        &nbsp;
                        {nft?.properties?.race}
                      </span>
                      <p className={styles.buyPriceETHHighlight}>
                        {`${nft?.order?.price} ${t('common.eth')}`}
                      </p>
                    </div>
                    <div className={styles.buyPrice}>
                      <span className={styles.buyId}>#{nft?.tokenId}</span>

                      {nft?.order?.price && (
                        <Price
                          eth={nft?.order.price}
                          className={styles.buyPriceUSD}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buyItem}>
                <div className={styles.buyNameTotal}>TOTAL</div>
                <div className={styles.buyPriceFlex}>
                  <p className={styles.buyPriceETH}>
                    {nft?.order?.price}&nbsp;{t('common.eth')}
                  </p>
                  &nbsp;&nbsp;
                  {nft?.order?.price && (
                    <Price
                      eth={nft?.order?.price}
                      className={styles.buyPriceUSDNormal}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.buyFooter}>
              <Button
                className={
                  !canBuy()
                    ? `tradeDisabled`
                    : `${styles.trade} ${styles.checkout}`
                }
                onClick={buyAsset}
                disabled={!canBuy() || buying}
              >
                {checkoutText()}
              </Button>
              <Button
                className={styles.cancelListing}
                onClick={() => setShowBuy(false)}
              >
                {'CANCEL'}
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.connectWalet}>
            <p className={styles.connectWaletText}>
              Before buy, please connect wallet.
            </p>
            <Wallet />
          </div>
        )}
      </Modal>
      <Modal
        visible={showCancel}
        title={'Are you sure you want to cancel your listings?'}
        closable={false}
      >
        <div className={styles.cancel}>
          <p className={styles.cancelContent} style={{ color: '#909090' }}>
            Canceling your listing will unpublish this sale from OpenBlox and
            requires a transaction to make sure it will never be fulfillable.
          </p>
          <Wallet />
        </div>
        <div className={styles.cancelFooter}>
          <Button className={`${styles.trade}`} onClick={cancelAsset}>
            {'CANCEL LISTINGS'}
          </Button>

          <Button
            className={`${styles.cancelListing}`}
            onClick={() => setShowCancel(false)}
          >
            {'CANCEL'}
          </Button>
        </div>
      </Modal>
    </BaseLayout>
  );
};

export default Asset;
