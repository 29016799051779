import React from 'react';
import styles from './table.module.css';

export type RecordType = Record<string, any>;

export interface Column {
  title: string;
  renderTitle?: (value: any, index: number) => React.ReactNode;
  key: string;
  width?: string;
  render?: (value: any, index: number) => React.ReactNode;
}

export interface TableProps {
  columns: Array<Column>;
  dataSource: Array<RecordType>;
  className?: string;
}

export interface TableHeaderProps {
  columns: Array<Column>;
}

export type TableBodyProps = TableProps;

const TableHeader: React.FC<TableHeaderProps> = ({ columns }) => {
  return (
    <div className={styles.tableHeader}>
      <div className={styles.tableHeaderRow}>
        {columns.map(({ title, renderTitle, key, width }, columnIndex) => {
          if (renderTitle) {
            return (
              <div
                className={styles.tableHeaderCell}
                key={key}
                style={{ width: width }}
              >
                {renderTitle(title,columnIndex)}
              </div>
            );
          }else {
            return (
              <div
                className={styles.tableHeaderCell}
                key={key}
                style={{ width: width }}
              >
                {title}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

const TableBody: React.FC<TableBodyProps> = ({
  columns,
  dataSource,
  className
}) => {
  return (
    <div className={styles.tableBody}>
      {dataSource.map((data, rowIndex) => {
        const rowHtml = [];
        rowHtml.push(
          <div className={styles.tableBodyRow} key={rowIndex}>
            {Object.keys(data).map((key, cellIndex) => {
              const column = columns[cellIndex];
              if (column.render) {
                return (
                  <div className={styles.tableBodyCell} key={key}  style={{ width: column.width }}>
                    {column.render(data[key], rowIndex)}
                  </div>
                );
              } else {
                return (
                  <div className={styles.tableBodyCell} key={key}  style={{ width: column.width }}>
                    {data[key]}
                  </div>
                );
              }
            })}
          </div>
        );
        return rowHtml;
      })}
    </div>
  );
};

const TableV2: React.FC<TableProps> = ({ columns, dataSource, className }) => {
  return (
    <div className={styles.tableWrapper}>
      <div className={styles.table}>
        <TableHeader columns={columns} />
        <TableBody
          columns={columns}
          dataSource={dataSource}
          className={className}
        />
      </div>
    </div>
  );
};

export default TableV2;
