import axios from '../axios';
export interface BalanceRequest {
  address: string;
}

export interface BalanceResponse {
  balance: number;
}

export const L1Balance = (params: BalanceRequest): Promise<BalanceResponse> => {
  let l1Host=process.env.REACT_APP_L1_HOST as string;
  l1Host=l1Host.substr(0,l1Host.lastIndexOf("/"))+"/api/";
  // axios.defaults.baseURL = l1Host;
  return axios.get(l1Host+'balance/l1', {
    params
  });
};
