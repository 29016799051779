import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider, getToken, AppCheck } from 'firebase/app-check';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID as string,
    appId: process.env.REACT_APP_FIREBASE_APP_ID as string
};

const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY as string),
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});

async function getAppCheckToken(appCheckInstnce: AppCheck) {
    let appCheckTokenResponse;
    try {
        appCheckTokenResponse = await getToken(appCheckInstnce, /* forceRefresh= */ false);
    } catch (err) {
        console.error(err);
        // Handle any errors if the token was not retrieved.
        return;
    }

    // Include the App Check token with requests to your server.
    return appCheckTokenResponse.token;
}

export { app, appCheck, getAppCheckToken };