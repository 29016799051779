import styles from './not-found.module.css';
import { BaseLayout } from 'layouts';

const NotFound = () => {
  return (
    <BaseLayout>
      <div className={styles.notFound}>
        <div className={styles.inner}>
          <h1 className={styles.title}> {'Not Found'}</h1>
        </div>
      </div>
    </BaseLayout>
  );
};

export default NotFound;
