import { useTranslation } from 'react-i18next';
import styles from './login.module.css';
import { ReactComponent as MetaMask } from 'assets/icons/metamask.svg';
import { BaseLayout } from 'layouts';
import { Wallet } from 'components';

const Login = () => {
  const { t } = useTranslation();
  return (
    <BaseLayout>
      <div className={styles.login}>
        <div className={`${styles.inner}`}>
          <h1 className={styles.title}>{t('views.login.title')}</h1>
          <div className={styles.metamaskWrapper}>
            <MetaMask className={styles.metamask} />
          </div>

          <div className={styles.connect}>
            <Wallet />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default Login;
