import React from 'react';
import styles from './radio.module.css';

interface CheckboxProps<T> {
  name: string;
  value: T;
  onChange: (value: T) => void;
  checked?: boolean;
  className?: string;
  children?: React.ReactNode;
}

function Radio<T>(props: CheckboxProps<T>) {
  const {
    name,
    value,
    onChange,
    checked = false,
    className = '',
    children
  } = props;

  return (
    <div className={`${styles.radio} ${className}`}>
      <input
        type="radio"
        checked={checked}
        name={name}
        className={styles.inner}
        onChange={() => onChange(value)}
      />
      <span className={styles.text}>{children}</span>
    </div>
  );
}

export default Radio;
